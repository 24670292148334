import React from 'react';
import {Link} from 'gatsby';
import {useTranslation} from 'react-i18next';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import {languageCourses} from '../../utils/utils';
import {withLocaleProvider} from '../../locale/LocaleProvider';

export const LanguageCoursesPage = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('Language-courses:seo:title')}
        description={t('Language-courses:seo:description')}
        url={t('Language-courses:seo:url')}
      />
      <Layout beforeFooter>
        <div className="breadcrumbs-v3 img-v3">
          <div className="container text-center">
            <p>{t('Language-courses:intro:subtitle')}</p>
            <h1>{t('Language-courses:intro:title')}</h1>
          </div>
        </div>
        <div className="container content-sm">
          <div className="lead text-center margin-bottom-40">
            <p
              dangerouslySetInnerHTML={{
                __html: t('Language-courses:contents:text-1'),
              }}
            />
          </div>
          <hr />
          <div className="heading heading-v5 margin-bottom-40">
            <h2>
              <strong>{t('Language-courses:contents:cards-title')}</strong>
            </h2>
          </div>
          <div className="row content-boxes-v4 content-boxes-v4-sm margin-bottom-30">
            {languageCourses
              .map((lng) => ({
                to: t(`Language-courses:contents:card-${lng}:link-url`),
                title: t(`Language-courses:contents:card-${lng}:title`),
                text: t(`Language-courses:contents:card-${lng}:text`),
              }))
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((item) => (
                <div className="col-md-4 md-margin-bottom-40" key={item.title}>
                  <i className="pull-left icon-flag"></i>
                  <div className="content-boxes-in-v4">
                    <Link to={item.to}>
                      <h2>{item.title}</h2>
                    </Link>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))
              .slice(0, 3)}
          </div>
          <hr />
          <div className="row content-boxes-v4 content-boxes-v4-sm margin-bottom-30">
            {languageCourses
              .map((lng) => ({
                to: t(`Language-courses:contents:card-${lng}:link-url`),
                title: t(`Language-courses:contents:card-${lng}:title`),
                text: t(`Language-courses:contents:card-${lng}:text`),
              }))
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((item) => (
                <div className="col-md-4 md-margin-bottom-40" key={item.title}>
                  <i className="pull-left icon-flag"></i>
                  <div className="content-boxes-in-v4">
                    <Link to={item.to}>
                      <h2>{item.title}</h2>
                    </Link>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))
              .slice(3)}
          </div>
        </div>
        <div
          className="parallax-counter-v2 parallaxBg1"
          style={{backgroundPosition: '50% 51px'}}
        >
          <div className="container">
            <ul className="row list-row">
              <li className="col-sm-6 col-xs-12 sm-margin-bottom-30">
                <div className="counters rounded language-courses">
                  <a href={t('Language-courses:contents:choice-1:link-url')}>
                    <h4 className="font-normal">
                      {t('Language-courses:contents:choice-1:text')}
                    </h4>
                  </a>
                </div>
              </li>
              <li className="col-sm-6 col-xs-12">
                <div className="counters rounded language-courses">
                  <a href={t('Language-courses:contents:choice-2:link-url')}>
                    <h4 className="font-normal">
                      {t('Language-courses:contents:choice-2:text')}
                    </h4>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="container content-sm">
          <p
            className="margin-bottom-40"
            dangerouslySetInnerHTML={{
              __html: t('Language-courses:contents:text-2'),
            }}
          />
          <div className="col-md-6">
            <div className="headline">
              <h4>{t('Language-courses:contents:list-1:title')}</h4>
            </div>
            <ul className="margin-bottom-40">
              <li>{t('Language-courses:contents:list-1:item-1')}</li>
              <li>{t('Language-courses:contents:list-1:item-2')}</li>
              <li>{t('Language-courses:contents:list-1:item-3')}</li>
              <li>{t('Language-courses:contents:list-1:item-4')}</li>
              <li>{t('Language-courses:contents:list-1:item-5')}</li>
              <li>{t('Language-courses:contents:list-1:item-6')}</li>
              <li>{t('Language-courses:contents:list-1:item-7')}</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="headline">
              <h4>{t('Language-courses:contents:list-2:title')}</h4>
            </div>
            <ul>
              <li>{t('Language-courses:contents:list-2:item-1')}</li>
              <li>{t('Language-courses:contents:list-2:item-2')}</li>
              <li>{t('Language-courses:contents:list-2:item-3')}</li>
              <li>{t('Language-courses:contents:list-2:item-4')}</li>
              <li>{t('Language-courses:contents:list-2:item-5')}</li>
              <li>{t('Language-courses:contents:list-2:item-6')}</li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', LanguageCoursesPage);
